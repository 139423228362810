<template>
	<div id="app">
		<b-navbar
			fixed-top
			transparent>
			<template slot="brand">
				<b-navbar-item href="https://brames.com.ua">
					<img
						src="./assets/brames_logo.svg"
						alt="Головна сторінка | brames">
				</b-navbar-item>
			</template>
			<template slot="start">
				<b-navbar-item href="#price"
					v-scroll-to="'#price'">
					Вартість
				</b-navbar-item>
				<b-navbar-item href="#about"
					v-scroll-to="'#about'">
					Що ми пропонуємо?
				</b-navbar-item>
				<b-navbar-item href="#registration"
					v-scroll-to="'#registration'">
					Реєстрація
				</b-navbar-item>
			</template>

			<template slot="end">
				<b-navbar-item tag="div">
					<div class="buttons">
						<a class="button is-black is-small"
							href="#registration"
							v-scroll-to="'#registration'">
							<strong>Зареєструватись</strong>
						</a>
					</div>
				</b-navbar-item>
			</template>
		</b-navbar>

		<section class="hero is-medium is-green">
			<div class="hero-body">
				<div class="container">
					<div class="columns is-centered">
						<div class="column is-7">
							<figure class="image is-2by1">
								<img src="./assets/images/ReadingSideDoodle.svg">
							</figure>
						</div>
						<div class="column is-5">
							<h1 class="title has-text-centered is-size-1">
								КУРСИ ЄВІ 2025
							</h1>
							<h1 class="title has-text-centered is-size-1">
								МАГІСТРАТУРА / АСПІРАНТУРА
							</h1>
							<h2 class="subtitle has-text-centered">
								Єдиний вступний іспит
							</h2>
							<h3>ПОГЛИБЛЕНИЙ КУРС ПІДГОТОВКИ ДО</h3>
							<p>Єдиного вступного іспиту (ЄВІ) в Магістратуру та Аспірантуру з англійської мови</p>

							<div class="is-flex justify-center has-margin-top-50">
								<a class="button is-black"
									href="#registration"
									v-scroll-to="'#registration'">
									<strong>Зареєструватись</strong>
								</a>
							</div>

							<!-- <h2 class="title is-size-3 has-text-centered has-margin-top-60 has-text-danger">
								Тренінг «Лайф-хаки ЄВІ»
							</h2>
							<h3 class="has-text-centered">Лише 2 дні  - 28 та 29 Червня о 16.00</h3>

							<div class="is-flex justify-center has-margin-top-20 has-margin-bottom-40">
								<a href="/assets/pdf/master-class-english.pdf"
									target="_blank"
									class="button is-danger">
									<strong>Детальнішe</strong>
								</a>
							</div> -->
						</div>
					</div>
				</div>

				<div class="level is-green is-marginless">
					<div class="level-item has-text-centered">
						<div>
							<p class="heading">Середній бал учнів</p>
							<p><span class="title">181</span></p>
						</div>
					</div>
					<div class="level-item has-text-centered">
						<div>
							<p class="heading">Підготували більше </p>
							<p><span class="title">250</span><span class="heading">учнів</span></p>
						</div>
					</div>
					<div class="level-item has-text-centered">
						<div>
							<p class="heading">Занять на тиждень</p>
							<p><span class="title">2</span></p>
						</div>
					</div>
					<div class="level-item has-text-centered">
						<div>
							<p class="heading">Ціна</p>
							<p><span class="title">190</span><span class="heading">грн/заняття</span></p>
						</div>
					</div>
					<div class="level-item has-text-centered">
						<div>
							<p class="heading">Набір триває до</p>
							<p class="title">31.10.2024</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="hero is-medium is-sand">
			<div class="hero-body">
				<div class="container">
					<div class="columns">
						<div class="column is-5 content">
							<p>Інтерактивна підготовка до <b>Єдиного вступного іспиту в Магістратуру та Аспірантуру</b> з Англійської мови (ЄВІ). Ти розберешся у найменших дрібницях іспиту, ліквідуєш наявні прогалини з Англійської мови та отримаєш максимальний результат на Єдиному вступному іспиті (ЄВІ, Магістратура / Аспірантура).</p>

							<p>Високий бал ЗНО з англійської мови – запорука вступу в Магістратуру та Аспірантуру в омріяному ВНЗ.</p>
						</div>
						<div class="column is-5 is-offset-2 has-text-right">
							<p class="is-size-4">Заняття будуть проходити в онлайн форматі в zoom в групах по 8-10 учнів</p>
							<p class="title is-size-1 has-margin-top-40 is-uppercase">ONLINE</p>
						</div>
					</div>

					<div class="columns">
						<div class="column is-6 is-offset-3">
							<figure class="image is-2by1">
								<img src="./assets/images/MessyDoodle.svg">
							</figure>
						</div>
					</div>

					<div class="columns">
						<div class="column is-12">
							<p class="title is-size-1 has-margin-top-40 is-uppercase">Переваги</p>
						</div>
					</div>

					<div class="columns is-multiline">
						<div class="column is-4 has-margin-bottom-80">
							<p class="is-size-4 has-border-top-width-3">Найкращі методики швидкого запам’ятовування, дієві поради та секрети успіху</p>
						</div>
						<div class="column is-4 has-margin-bottom-80">
							<p class="is-size-4 has-border-top-width-3">Викладачі з КНУ імені Тараса Шевченка, члени комісії ЄВІ, досвід підготовки більше 7 років</p>
						</div>
						<div class="column is-4 has-margin-bottom-80">
							<p class="is-size-4 has-border-top-width-3">Доступність (дуже приваблива ціна🙃)</p>
						</div>
						<div class="column is-4 has-margin-bottom-80">
							<p class="is-size-4 has-border-top-width-3">Вся програма ЄВІ в одному курсі</p>
						</div>
						<div class="column is-4 has-margin-bottom-80">
							<p class="is-size-4 has-border-top-width-3">Аналіз успішності</p>
						</div>
						<div class="column is-4 has-margin-bottom-80">
							<p class="is-size-4 has-border-top-width-3">Безкоштовний авторський посібник підготовки до ЄВІ</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section id="price" class="hero is-medium is-blue">
			<div class="hero-body">
				<div class="container">

					<div class="columns">
						<div class="column is-8">
							<p class="title is-size-1 is-uppercase">Вартість навчання</p>
						</div>
						<div class="column is-4">
							<p class="title is-size-1 is-uppercase">190 грн</p>
							<p class="heading">за заняття</p>
						</div>
					</div>

					<div class="columns is-multiline">
						<div class="column is-4 has-margin-bottom-80">
							<p class="is-size-4 has-border-top-width-3">Заняття проходять 2 рази на тиждень по 1 годині 20 хв</p>
						</div>
						<div class="column is-4 has-margin-bottom-80">
							<p class="is-size-4 has-border-top-width-3">Заняття проходять в он-лайн режимі</p>
						</div>
						<div class="column is-4 has-margin-bottom-80">
							<p class="is-size-4 has-border-top-width-3">Старт груп по мірі наповнення з 14.10</p>
						</div>
						<div class="column is-4 has-margin-bottom-80">
							<p class="is-size-4 has-border-top-width-3">Знижка 7% при реєстрації до 27.09.2024</p>
						</div>
						<div class="column is-4 has-margin-bottom-80">
							<p class="is-size-4 has-border-top-width-3">Курс розрахований на 7 місяців</p>
						</div>
						<div class="column is-4 has-margin-bottom-80">
							<p class="is-size-4 has-border-top-width-3">Оплата після пробного заняття за курс</p>
						</div>
					</div>

					<div class="columns">
						<div class="column is-8 is-offset-2">
							<figure class="image is-2by1">
								<img src="./assets/images/IceCreamDoodle.svg">
							</figure>
						</div>
					</div>

				</div>
			</div>
		</section>

		<section id="about" class="hero is-medium is-green">
			<div class="hero-body">
				<div class="container">

					<div class="columns">
						<div class="column is-12">
							<p class="title is-size-1 is-uppercase">Що ми пропонуємо?</p>
						</div>
					</div>
					
					<div class="content is-size-4 has-border-top-width-3">
						<ul>
							<li>Інтерактивні заняття форматі гри, без нудних лекцій чи зазубрювання</li>
							<li>Купа наочного матеріалу, з повним зануренням в предмет</li>
							<li>Конспект посібник усіх занять з тестами</li>
							<li>Спеціально розроблений для швидкого запам’ятовування теоретичний матеріал видається додому</li>
							<li>Заняття адаптовані спеціально під тести Єдиного вступного іспиту (ЄВІ, Магістратура та Аспірантура)</li>
							<li>Кожне завдання пропрацьовується у вигляді різних інтерактивних активностей</li>
						</ul>
					</div>
				</div>
			</div>
		</section>

		<section class="hero is-medium is-sand">
			<div class="hero-body">
				<div class="container">

					<div class="columns">
						<div class="column is-12">
							<p class="title is-size-1 is-uppercase">Цей курс для тебе, якщо:</p>
						</div>
					</div>

					<div class="columns align-items-center">
						<div class="column is-5">
							<div class="is-4 has-margin-bottom-80">
								<p class="is-size-4 has-border-top-width-3">Тобі необхідно скласти ЄВІ на найвищий бал, щоб вступити у найкращий ВНЗ</p>
							</div>
							<div class="is-4 has-margin-bottom-80">
								<p class="is-size-4 has-border-top-width-3">Застарілі методи викладання у школі заганяють тебе у стрес</p>
							</div>
							<div class="is-4 has-margin-bottom-80">
								<p class="is-size-4 has-border-top-width-3">Ти давно вчив англійську, все забув, але бажаєш скласти іспит</p>
							</div>
						</div>
						<div class="column is-7">
							<figure class="image is-2by1">
								<img src="./assets/images/LayingDoodle.svg">
							</figure>
						</div>
					</div>

				</div>
			</div>
		</section>

		<section class="hero is-medium is-blue">
			<div class="hero-body">
				<div class="container">

					<div class="columns">
						<div class="column is-12">
							<p class="title is-size-1 is-uppercase">В результаті ти:</p>
						</div>
					</div>

					<div class="columns align-items-center">
						<div class="column is-7">
							<figure class="image is-2by1">
								<img src="./assets/images/DoogieDoodle.svg">
							</figure>
						</div>
						<div class="column is-5">
							<div class="is-4 has-margin-bottom-80">
								<p class="is-size-4 has-border-top-width-3">Ти складеш Єдиний вступний іспит (ЄВІ) на максимум</p>
							</div>
							<div class="is-4 has-margin-bottom-80">
								<p class="is-size-4 has-border-top-width-3">Полюбиш навчатись та сам схочеш стати репетитором чи блогером з корисних порад для підготовки</p>
							</div>
							<div class="is-4 has-margin-bottom-80">
								<p class="is-size-4 has-border-top-width-3">Весело та захопливо проведеш час</p>
							</div>
						</div>
					</div>

				</div>
			</div>
		</section>

		<section id="registration" class="hero is-medium is-white">
			<div class="hero-body">
				<div class="container">

					<div class="columns">
						<div class="column is-12">
							<h2 class="title is-size-1 is-uppercase">Реєстрація на пробне заняття</h2>
							<h3 class="heading">із ЗНО-Магістратури з англійської мови (ЄВІ)</h3>
						</div>
					</div>

					<div class="columns">
						<div class="column is-5">
							<p class="is-size-4 has-border-top-width-3 has-margin-bottom-40">Бажаєте записатись на пробне заняття або дізнатись більше?<br>Заповність форму реєстрації та ми передзвонимо Вам за пів години</p>
							<p>Ви також можете написати нам на пошту: <a href="mailto:info@brames.com.ua?subject=brames">info@brames.com.ua</a></p>
							<p>Або зателефонуйте на номер: <a href="tel:+380977270727">+380977270727</a> та <a href="tel:+380730270727">+380730270727</a></p>

							<p>
								<br>
								<br>
								<a href="https://instagram.com/zno_brames" target="_blank" class="button is-danger">
									<strong>інста brames school</strong>
								</a>
							</p>
						</div>

						<div v-if="sent"
							class="column is-6 is-offset-1">
							<h2 class="title is-size-1 is-uppercase">Дякуємо! 🎉</h2>
							<p>Ура! Ми отримали твої контакти. Зателефонуємо якомога швидше. Гарного дня!</p>
							<b-button
								type="is-dark"
								size="is-small"
								class="has-margin-top-40"
								@click="sent = false">
								<b>Надіслати ще раз</b>
							</b-button>
							<br>
							<small>Думаєш, треба зареєструвати подругу чи друга? 😏</small>
						</div>

						<div v-else-if="error"
							class="column is-6 is-offset-1">
							<h2 class="title is-size-1 is-uppercase">😵 От халепа...</h2>
							<p>Ми не змогли отримати твої дані. Спробуй зателефонувати або надіслати нам листа власноруч</p>
							<b-button
								type="is-dark"
								size="is-small"
								class="has-margin-top-40"
								@click="sent = false">
								<b>Спробувати ще раз</b>
							</b-button>
							<br>
							<small>Уважно перевір всі дані, які вводиш</small>
						</div>

						<div v-else
							class="column is-6 is-offset-1">
							<validation-observer ref="observer" v-slot="{ passes, invalid }">

								<BInputWithValidation
									v-model="name"
									rules="required"
									label="П.І.П."
									size="is-large"
									required>
								</BInputWithValidation>

								<BInputWithValidation
									v-model="university"
									rules="required"
									label="ВНЗ"
									size="is-large"
									required>
								</BInputWithValidation>

								<BInputWithValidation
									v-model="specialty"
									rules="required"
									label="Спеціальність"
									size="is-large"
									required>
								</BInputWithValidation>

								<BInputWithValidation
									v-model="phone"
									rules="required|phone"
									label="Номер телефону"
									size="is-large"
									type="tel"
									required>

									<template #prefix>
										<p class="control">
											<a class="button is-static is-large">+380</a>
										</p>
									</template>
								</BInputWithValidation>

								<b-button
									type="is-dark"
									size="is-large"
									class="has-margin-top-40"
									@click="passes(submit)"
									:disabled="invalid">
									<b>Зареєструватись</b>
								</b-button>

								<br>
								<br>

								<p>
									<a href="/assets/pdf/Договір-ЄВІ-2024-он-лайн.pdf" target="_blank" style="text-decoration: underline; text-color: rgb(98, 98, 250)">
										Договір оплатного надання послуг
									</a>
								</p>

							</validation-observer>
						</div>
					</div>
				</div>
			</div>
		</section>

		<footer class="footer">
		</footer>
	</div>
</template>

<script>
import axios from 'axios'
import { ValidationObserver } from 'vee-validate'
import BInputWithValidation from './components/BInputWithValidation'

export default {
	components: {
		ValidationObserver,
		BInputWithValidation,
	},
	data: () => ({
		name: '',
		university: '',
		specialty: '',
		phone: '',
		error: false,
		sent: false,
	}),
	methods: {
		async submit() {
			try {
				const { data } = await axios.post('https://api.brames.com.ua/api/mail/magistracy', {
					name: this.name,
					university: this.university,
					specialty: this.specialty,
					phone: `+380${this.phone}`,
				})

				if(data.ok === true) {
					this.sent = true
				} else {
					this.error = true
				}
			} catch (error) {
				this.error = true
			}
		},
	},
}
</script>

<style lang="scss">
@import './scss/main.scss';

</style>
