<template>
	<ValidationProvider
		:vid="vid"
		:name="$attrs.name || $attrs.label"
		:rules="rules"
		v-slot="{ errors, valid }">
		<b-field
			v-bind="$attrs"
			:type="{ 'is-danger': errors[0], 'is-success': valid }"
			:message="errors">
			<div class="field has-addons">
				<slot name="prefix"></slot>
				<b-input v-model="innerValue" v-bind="$attrs" expanded></b-input>
			</div>
		</b-field>
	</ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
	components: {
		ValidationProvider
	},
	props: {
		vid: {
			type: String
		},
		rules: {
			type: [Object, String],
			default: ""
		},
		// must be included in props
		value: {
			type: null
		}
	},
	data: () => ({
		innerValue: ""
	}),
	watch: {
		// Handles internal model changes.
		innerValue(newVal) {
			this.$emit("input", newVal);
		},
		// Handles external model changes.
		value(newVal) {
			this.innerValue = newVal;
		}
	},
	created() {
		if (this.value) {
			this.innerValue = this.value;
		}
	}
}
</script>
