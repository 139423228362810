import { required } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import PhoneNumber from 'awesome-phonenumber'

extend('required', {
	...required,
	message: 'Це поле обов\'язкове'
})

extend('phone', {
    message(fieldName) {
        return `${fieldName} має бути у форматі +380960000000`
    },
    validate(value) {
        return new Promise(resolve => {
            let phone = new PhoneNumber(`+380${value}`)
            resolve({ valid: phone.isValid() })
        })
    }
})